<template>
  <app-collapse accordion>
    <app-collapse-item
      :isVisible="true"
      headerBgVariant="secondary"
    >
      <template #header>
        <b-card-title class="text-white"><feather-icon icon="AlertCircleIcon" /> Thông Tin Đăng Ký</b-card-title>
      </template>
      <div style="padding: 20px">
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Họ Tên"
              label-cols-md="3"
            >
              <validation-provider
                #default="{ errors }"
                name="fullName"
                rules="required"
                :customMessages="{['required']: 'Không được bỏ trống'}"
              >
                <b-form-input
                  v-model="form.fullName"
                  :state="errors.length > 0 ? false:null"
                  @change="onChange"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Điện Thoại"
              label-cols-md="3"
            >
              <validation-provider
                #default="{ errors }"
                name="phoneNumber"
                rules="required"
                :customMessages="{['required']: 'Không được bỏ trống'}"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="SmartphoneIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    id="hi-number"
                    v-model="form.phone"
                    type="number"
                    placeholder="Số điện thoại chính"
                    :state="errors.length > 0 ? false:null"
                    @change="onChange"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Ngày Sinh"
              label-cols-md="3"
            >
              <b-row no-gutters>
                <b-col cols="6" class="ft-gutters-padding-10px">
                  <validation-provider
                    #default="{ errors }"
                    name="birthday"
                    :customMessages="{['required']: 'Không được để trống'}"
                  >
                    <b-form-datepicker
                      placeholder="Ngày sinh"
                      v-model="form.birthday"
                      :state="errors.length > 0 ? false:null"
                      @change="onChange"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
                <b-col cols="6">
                  <b-form-select
                    v-model="form.gender"
                    :options="genderOptions"
                    @change="onChange"
                  /> 
                </b-col>
              </b-row>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Email"
              label-cols-md="3"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-input-group class="input-group-merge">
                  <b-input-group-prepend is-text>
                    <feather-icon icon="MailIcon" />
                  </b-input-group-prepend>
                  <b-form-input
                    v-model="form.emailCustomer"
                    placeholder="Email của khách hàng"
                    :state="errors.length > 0 ? false:null"
                    @change="onChange"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-row>
              <b-col>
                <b-form-group
                  label="Địa Chỉ"
                  label-cols-md="3"
                >
                  <b-row no-gutters style="padding-bottom: 10px">
                    <b-col class="ft-gutters-padding-10px">
                      <validation-provider
                        #default="{ errors }"
                        name="Tỉnh thành"
                        :customMessages="{['required']: 'Không được để trống'}"
                      >
                        <b-form-select
                          v-model="form.province"
                          :options="getProvinces"
                          @change="selectedProvince"
                        />
                        <small class="text-danger">{{ errors[0]}}</small>
                      </validation-provider>
                    </b-col>
                    <b-col class="ft-gutters-padding-10px">
                      <b-form-select
                        v-model="form.district"
                        :options="address.districts"
                        @change="selectedDistrict" />
                    </b-col>
                    <b-col>
                      <b-form-select
                        v-model="form.ward"
                        :options="address.wards"
                        @change="onChange"
                      />
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-textarea v-model="form.street" placeholder="Đường, ngõ, xóm thôn..." @change="onChange"/>
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Quốc Gia"
              label-cols-md="3"
            >
              <b-form-select
                v-model="form.country" :options="countryCode" @change="onChange"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </app-collapse-item>
  </app-collapse>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import AppCollapse from '@/@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@/@core/components/app-collapse/AppCollapseItem.vue'
import constants from '@/@core/app-config/constants'
import {
  BFormDatePicker, BInputGroup, BInputGroupPrepend, BCardBody, BCardTitle, BCardSubTitle, BCardHeader, BFormInput, BFormDatepicker, BFormGroup, BForm, BRow, BCol, BButton, BCard, BFormSelect, BFormSelectOption
} from 'bootstrap-vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

import PcVn from 'pc-vn'
import CountryCode from '@/@core/app-config/countryCode'

export default {
  data() {
    return {
      address: {
        provinces: [{ text: 'Tỉnh / Thành', value: null }],
        districts: [{ text: 'Quận / Huyện', value: null }],
        wards: [{ text: 'Thị xã', value: null }],
      },
    }
  },
  methods: {
    selectedProvince(e) {
      const list = PcVn.getDistrictsByProvinceCode(e)
      this.form.district = null// về mặc đinh
      this.address.districts = [...this.address.districts, ...list.map(p => {
        return {
          value: p.code,
          text: p.name,
        }
      })]
      console.log('change selectedProvince', this.address.districts)
    },
    onChange() {
      console.log('onChange');
    },
    selectedDistrict(e) {
      const list = PcVn.getWardsByDistrictCode(e)
      this.form.ward = null// về mặc đinh
      this.address.wards = [this.address.wards[0], ...list.map(p => {
        return {
          value: p.code,
          text: p.name,
        }
      })]
    },
  },
  computed: {
    genderOptions() {
      console.log('constants.genderOptions', constants.genderOptions);
      return constants.genderOptions
    },
  },
  props: {
    form: {
      type: Object,
    }
  },

  components: {
    BFormDatePicker,
    BInputGroup,
    BInputGroupPrepend,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BFormDatepicker,
    BFormSelect,
    BFormSelectOption,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BCard,
    FeatherIcon,
    AppCollapse,
    AppCollapseItem,
  },
  computed: {
    countryCode() {
      return CountryCode.map((item, k) => {
        return {
          text: item.name,
          value: item.code,
        }
      })
    },
    getProvinces() {
      const provinces = PcVn.getProvinces()
      return [this.address.provinces[0], ...provinces.map(p => {
        return {
          value: p.code,
          text: p.name,
        }
      })]
    },
  },
}
</script>
