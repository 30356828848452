<template>
  <b-card no-body>
    <b-card-header>
      <b-cart-title><span style="font-weight: bold; font-size: 1.2em"><feather-icon icon="UserIcon" size="26" /> {{ id ? 'Sửa Thông Tin Nhân Viên' : 'Tạo Nhân Viên Mới'}}</span></b-cart-title>
      <b-card-sub-title>
        <b-button variant="success" @click="validationForm"><feather-icon icon="SaveIcon" /> {{ id ? 'Cập Nhật' : 'Tạo Mới'}}</b-button>
      </b-card-sub-title>
    </b-card-header>
    <b-card-body>
      <validation-observer ref="createStaff">
        <b-row>
          <!-- thông tin đăng ký -->
          <b-col cols="8">
            <info :form="form"/>
          </b-col>
          <!-- kích hoạt -->
          <b-col cols="4">
            <b-row>
              <b-col cols="12">
                <active :form="form" :data="data.active"/>
              </b-col>
              <b-col cols="12">
                <div style="margin-top: 10px">
                  <group :form="form.groups" :data="data.groupOptions"/>
                </div>
              </b-col>
              <b-col cols="12">
                <div style="margin-top: 10px">
                  <role :form="form.roles" :data="data.roleOptions"/>
                </div>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </validation-observer>
    </b-card-body>
  </b-card>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import {
  BCardBody, BCardSubTitle, BCardHeader, BCardTitle, BFormDatepicker, BFormGroup, BForm, BRow, BCol, BButton, BCard, BFormSelect, BFormSelectOption
} from 'bootstrap-vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'
import Info from './components/Info.vue'
import Active from './components/Active.vue'
import Group from './components/Group.vue'

import Role from './components/Role.vue'
export default {
  props: {
    id: {
      type: Number,
      default: 0,
    },
  },
  components: {
    ValidationObserver,
    Role,
    BCardHeader,
    BCardBody,
    BCardSubTitle,
    BRow,
    BCol,
    BButton,
    BCard,
    FeatherIcon,
    Info,
    Active,
    Group,
    BCardTitle,
},
  methods: {
    validationForm() {
      console.log('submit')
    },
  },
  data() {
    return {
      data: {
        active: true,
        roleOptions: [{
          id: 1, name: 'MKT',
          id: 2, name: 'SALE'
        }],
        groupOptions: [{
          value: 2, label: 'Chọn Nhóm TK'
        }],
      },
      form: {
        staff_name: '',
        staff_password: '',
        phone: null,
        birthday: null,
        type: '',
        gender: null,
        district: null,
        districts: null,
        province: null,
        ward: null,
        country: 'VN',
        roles: [],
        groups: [],
      },
      required,
    }
  },
}
</script>
<style lang="sass">
  @import '@core/scss/vue/libs/vue-select.scss'
</style>
<style>
  .color-input{
    width: 50%
  }
  .ft-gutters-padding-10px {
    padding-right: 10px!important;
  }
</style>
